
import { defineComponent, ref, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiBase, ApilogisticsDoc } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import ElSelect from "element-plus/es/components/select";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { MerchantOption, TaggingItem } from "@/core/directive/interface/common";

import PermissionCommon from "@/components/layout/PermissionCommon.vue";

interface AddressData {
  key: number;
  value: string;
}

export default defineComponent({
  name: "double-sale-order-shipment-label-add",
  emits: ["update-list", "reset-form"],
  components: {
    PermissionCommon,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    const formRef = ref<null | HTMLFormElement>(null);
    const AddLogisticsDocRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      warehouse_name: "",
      logistics_limit: 0,
      company_id: undefined,
      sender_address_id: undefined,
      recipient_address_id: undefined,
      service_type: "",
      pick_up_date: "",
      package_size_l: 0,
      package_size_w: 0,
      package_size_h: 0,
      package_weight: 0,
      package_type: "",
      package_num: 0,
      package_fee: 0,
      interior_category: "",
      email: "",
      notes: "",
    });

    /*
     *必填项验证
     */
    const rules = ref({
      warehouse_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      logistics_limit: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      company_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      sender_address_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      recipient_address_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pick_up_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_size_l: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_size_w: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_size_h: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_weight: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_num: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_fee: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      interior_category: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          type: "email",
          message: t("shipmentLabel.emailVerifyTip"),
          trigger: "change",
        },
      ],
      notes: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    /*
     *下拉框配置
     */
    const options = ref({
      merchant: [] as MerchantOption[],
      merchantLoading: false,
      senderAddress: [] as AddressData[],
      senderAddressLoading: false,
      recipientAddress: [] as AddressData[],
      recipientAddressLoading: false,
      packageType: [] as TaggingItem[],
      packageTypeLoading: false,
      interiorCategory: [] as TaggingItem[],
      interiorCategoryLoading: false,
      serviceType: [] as TaggingItem[],
      serviceTypeLoading: false,
    });

    /*
     *下拉选择公司名称
     */
    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };
    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );
    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    /*
     *根据公司ID获取关联地址
     */
    const getAddressData = (id?, remake?) => {
      if (id) {
        getSenderAddressData(id, remake);
        getRecipientAddressData(id, remake);
      }
    };
    const getSenderAddressData = async (id?, remake?) => {
      if (remake) {
        // formData.value.sender_address_id = [];
        options.value.senderAddress = [];
      }
      options.value.senderAddressLoading = true;
      const { data } = await ApilogisticsDoc.getOwnerAddress({
        company_id: id,
        type: "sender_address",
      });
      options.value.senderAddressLoading = false;
      if (data.code == 0) {
        options.value.senderAddress = data.data;
      }
    };
    const getRecipientAddressData = async (id?, remake?) => {
      if (remake) {
        // formData.value.recipient_address_id = [];
        options.value.recipientAddress = [];
      }
      options.value.recipientAddressLoading = true;
      const { data } = await ApilogisticsDoc.getOwnerAddress({
        company_id: id,
        type: "logistics_recipient_address",
      });
      options.value.recipientAddressLoading = false;
      if (data.code == 0) {
        options.value.recipientAddress = data.data;
      }
    };

    /*
     *提交
     */
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApilogisticsDoc.addLogisticsDoc(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                updateList();
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    /*
     *获取Tagging
     */
    const getTaggingData = () => {
      options.value.packageTypeLoading = true;
      options.value.interiorCategoryLoading = true;
      options.value.serviceTypeLoading = true;
      ApiBase.getTaggingData({
        short_key: [
          "package_packaging_type",
          "internal_parts_category",
          "package_service_type",
        ],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.packageType = data.data.package_packaging_type.items;
            options.value.interiorCategory =
              data.data.internal_parts_category.items;
            options.value.serviceType = data.data.package_service_type.items;
            options.value.packageTypeLoading = false;
            options.value.interiorCategoryLoading = false;
            options.value.serviceTypeLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *关闭弹框
     */
    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = {
        warehouse_name: "",
        logistics_limit: 0,
        company_id: undefined,
        sender_address_id: undefined,
        recipient_address_id: undefined,
        service_type: "",
        pick_up_date: "",
        package_size_l: 0,
        package_size_w: 0,
        package_size_h: 0,
        package_weight: 0,
        package_type: "",
        package_num: 0,
        package_fee: 0,
        interior_category: "",
        email: "",
        notes: "",
      };
    };
    const handleDiscard = () => {
      hideModal(AddLogisticsDocRef.value);
    };
    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };
    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getTaggingData(), getMerchantSourceData("")])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    onMounted(() => {
      modalShowListener(AddLogisticsDocRef.value, () => {
        resetForm();
        getFromInfo();
      });
      modalHideListener(AddLogisticsDocRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      props,
      loading,
      formData,
      rules,
      formRef,
      AddLogisticsDocRef,
      submitButton,
      options,
      submit,
      resetForm,
      searchMerchantSourceItems,
      getAddressData,
      handleDiscard,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title max-sm-w-100" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1 max-sm-w-100" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-primary max-sm-flex-1",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_logistics_doc"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0 vip-table" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "svg-icon svg-icon-3 svg-icon-success" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "svg-icon svg-icon-3 svg-icon-danger" }
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_NewWarehouse = _resolveComponent("NewWarehouse")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-14 max-sm-w-100",
            placeholder: _ctx.t('common.search')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.search]
          ]),
          _withDirectives(_createElementVNode("span", {
            class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
          }, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
          ], 512), [
            [_vShow, _ctx.search != '']
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("shipmentLabel.newWarehouse")), 1)
          ])), [
            [_directive_auth, { auth: ['add'] }]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange
      }, {
        "cell-warehouse_name": _withCtx(({ row: logisticsDocItem }) => [
          _createTextVNode(_toDisplayString(logisticsDocItem.warehouse_name), 1)
        ]),
        "cell-logistics_total": _withCtx(({ row: logisticsDocItem }) => [
          _createTextVNode(_toDisplayString(logisticsDocItem.logistics_total), 1)
        ]),
        "cell-logistics_available": _withCtx(({ row: logisticsDocItem }) => [
          _createElementVNode("div", {
            class: _normalizeClass({
            'text-danger':
              logisticsDocItem.logistics_available <=
              logisticsDocItem.logistics_limit,
          })
          }, _toDisplayString(logisticsDocItem.logistics_available), 3)
        ]),
        "cell-logistics_limit": _withCtx(({ row: logisticsDocItem }) => [
          _createTextVNode(" ≤ " + _toDisplayString(logisticsDocItem.logistics_limit), 1)
        ]),
        "cell-status": _withCtx(({ row: logisticsDocItem }) => [
          (logisticsDocItem.status == 10)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen043.svg" })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen044.svg" })
                ])
              ]))
        ]),
        "cell-action": _withCtx(({ row: logisticsDocItem }) => [
          _createVNode(_component_router_link, {
            to: '/double-sale-order/shipment-label/' +
            logisticsDocItem.id +
            '/list'
            ,
            class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 my-3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_16, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange"])
    ]),
    _createVNode(_component_NewWarehouse, { onUpdateList: _ctx.updateList }, null, 8, ["onUpdateList"])
  ]))
}
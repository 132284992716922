
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import { ApilogisticsDoc } from "@/core/api";
import _ from "lodash";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { MBDatatable } from "magicbean-saas-common";
import NewWarehouse from "./NewWarehouse.vue";
import { setModuleBCN } from "@/core/directive/function/common";

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface LogisticsDocIndexTableData {
  company_id: number;
  id: number;
  is_low_stock: number;
  logistics_available: number;
  logistics_limit: number;
  logistics_total: number;
  status: number;
  warehouse_name: string;
}

export default defineComponent({
  name: "double-sale-order-shipment-label",
  components: {
    MBDatatable,
    NewWarehouse,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const tableData = ref<Array<LogisticsDocIndexTableData>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref("");
    const sortOptions = ref<Array<any>>([
      {
        field: "id",
        direction: "desc",
      },
    ]);
    const filterOptions = ref<Array<any>>([]);
    const tableHeader = ref([
      {
        name: t("shipmentLabel.warehouse"),
        key: "warehouse_name",
        sortable: false,
      },
      {
        name: t("shipmentLabel.shipmentLabelTotal"),
        key: "logistics_total",
        sortable: false,
      },
      {
        name: t("shipmentLabel.shipmentLabelAvailable"),
        key: "logistics_available",
        sortable: false,
      },
      {
        name: t("shipmentLabel.shipmentLabelAlarmLimit"),
        key: "logistics_limit",
        sortable: false,
      },
      {
        name: t("shipmentLabel.status"),
        key: "status",
        sortable: false,
        align: "right",
      },
      {
        name: t("shipmentLabel.action"),
        key: "action",
        sortable: false,
        align: "right",
      },
    ]);

    /*
     *拉取数据
     */
    const getLogisticsDocIndex = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApilogisticsDoc.getLogisticsDocIndex({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const updateList = () => {
      getLogisticsDocIndex(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    /*
     *搜索功能
     */
    const debounceSearch = _.debounce(getLogisticsDocIndex, 1000);
    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    /*
     *分页功能
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getLogisticsDocIndex(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getLogisticsDocIndex(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    onMounted(() => {
      init();
    });
    onActivated(() => {
      init();
    });
    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      searchItems,
      removeSearch,
      onCurrentPageChange,
      onRowsPerPageChange,
      updateList,
    };
  },
});
